// ** React Imports
import { useEffect } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Spinner Import
import { shallow } from 'zustand/shallow'
import { useUser } from '@propelauth/nextjs/client'
import { Spinner } from 'src/components/spinner'

// ** Hook Imports

import { routes } from 'src/common/constants/routes.constants'
import useStore from '../modules/wfe/wfe.store'

const selector = (state: any) => ({
  setUser: state.setUser
})

/**
 *  Set Post Login URL based on User Roles
 */
export const getPostLoginRoute = () => {
  return routes.home.path
}

const Root = () => {
  // ** Hooks
  const { user } = useUser()
  const router = useRouter()
  const { setUser } = useStore(selector, shallow)

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    if (user) {
      setUser(user)
      const PostLoginRoute = getPostLoginRoute()
      router.replace(PostLoginRoute)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Spinner />
}

export default Root
